export default {
  'dirs.title': 'Registries',
  'dirs.add New': 'Add New',
  'dirs.modal add.title': 'Add/Edit Registry',
  'table.name (unique)': 'Name (unique)',
  'table.Title': 'Title',
  'table.Description': 'Description',
  'table.External': 'External',
  'table.Actions': 'Actions',
  'entries.Dirs': 'Directory',

  'formDir.name.label': 'Name',
  'formDir.name.required': 'Name is required',
  'formDir.title.label': 'Title',
  'formDir.title.required': 'Title is required',
  'formDir.description.label': 'Description',
  'formDir.description.required': 'Description is required',
  'formDir.external.label': 'External',
  'formDir.submit': 'Submit',
  'formDir.cancel': 'Cancel',
};
