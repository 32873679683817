import request from '@/utils/request';
import {gqlMutate} from '@/utils/graphql-client';
import {gql} from '@apollo/client';

export async function login(variables) {
  const query = gql`
    mutation($username: String!, $password: String!) {
      login(input: {identifier: $username, password: $password}) {
        jwt
        user {
          id
          username
          email
          confirmed
          blocked
          role {
            id
            name
            description
            type
          }
          avatar {url, formats}
        }
      }
    }
  `;

  return gqlMutate(query, variables, 'login', true, {noAuth: true})
}

export async function fakeAccountLogin(params) {
  return request('/api/login/account', {
    method: 'POST',
    data: params,
  });
}
export async function getFakeCaptcha(mobile) {
  return request(`/api/login/captcha?mobile=${mobile}`);
}
