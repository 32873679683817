import { history } from 'umi';
import {login} from '@/pages/user/login/service';
import { getPageQuery } from '@/utils/utils';
import { message } from 'antd';

const Model = {
  namespace: 'login',
  state: {
    hasError: false,
  },
  effects: {
    *login({ payload }, { call, put }) {
      let res = yield call(login, payload);
      console.log(res)

      if (res) {
        yield put({type: 'setError', payload: false})

        const {jwt, user} = res;

        localStorage.setItem('token', jwt)
        yield put({type: 'user/setUser', payload: user}); // Login successfully

        message.success('Welcome!');

        // check redirect
        // const urlParams = new URL(window.location.href);
        const params = getPageQuery();
        let { redirect } = params;

        if (redirect && /^\/\w+/i.test(redirect)) {
          history.push(redirect);
          return;
        }

        history.push('/');

      } else {
        yield put({type: 'setError', payload: true})
      }
    },

    *logout({}, {put}) {
      localStorage.removeItem('token');
      yield put({type: 'user/setUser', payload: null});
      history.push('/');
    },
  },
  reducers: {
    setError(state, { payload: hasError }) {
      return { ...state, hasError };
    },
  },
};
export default Model;
