export default {
  'table.UserName': 'Колдонуучу',
  'table.Role': 'Роль',
  'table.Created': 'Түзүлгөн',
  'table.Edit': 'Түзөтүү',
  'table.Delete': 'Жок кылуу',
  'table.Name': 'Аты',
  'table.KRDescription': 'Кр.сүрөттөлүшү',
  'table.Start': 'Башталышы',
  'table.Finish': 'Бүтүшү',
  'table.Subscribe': 'Кол коюлган',
  'table.Updated': 'Жаңыртылды',
  'table.Type': 'Типы',
  'table.Status': 'Абал',
  'table.Action': 'Иш-аракет',
  'table.Details': 'Кененирээк',
  'table.Payer': 'Төлөөчү',
  'table.Done': 'Аткарылды',
  'table.File': 'Файл',
  'table.PayerAccount': 'Төлөөчүнүн эсеби',
  'table.PayerBank': 'Төлөөчүнүн банкы',
  'table.RecipientName': 'Алуучу',
  'table.RecipientAccount': 'Алуучунун эсеби',
  'table.RecipientBank': 'Алуучунун Банкы',
  'table.AssignmentPayment': 'Төлөмдүн максаты',
  'table.name (unique)': 'Имя (уник.)',
  'table.Title': 'Название',
  'table.External': 'Внешний',
  'table.Published': 'Жарыяланды',
  'table.Project': 'Долбоор',
  'table.Description': 'Сүрөттөмө',
  'table.Outside': 'Тышкы',
  'table.Actions': 'Аракеттер',

  'table.Create': 'Кошуу',
  'table.Save': 'Сактоо',
  'table.AmountPlan': 'План',
  'table.AmountFact': 'Факт',
  'table.AmountRemainder': 'Калган',
  'table.Risk': 'Тобокелдиктер',
  'table.Task': 'Тапшырма',
  'table.ImportanceRisk': 'Тобокелдиктин маанилүүлүгү',
  'table.Product': 'Продуктылар',
  'table.Indicators': 'Көрсөткүчтөр',
  'table.Bank': 'Банк',
  'table.PaymentDate': 'Төлөө датасы',
  'table.Results': 'Жыйынтыктар',
  'table.TypeReport': 'Отчетунун түрү',
  'table.Deadlines': 'Мөөнөтү',
  'table.Notation': 'Эскертүү',
  'table.Sum': 'Суммасы',
  'table.Currency': 'Валюта',

  //Home
  'home.news': 'Новости',
  'home.news Publications': 'Долбоорлор жана Басылмалар',
  'home.about.platform': 'Платформа жөнүндө',
  'home.projects': 'Долбоорлор',
  'home.donors': 'Донорлор',

  // Login
  'pages.login.accountLogin.tab': 'Вход в Аккаунт',
  'pages.login.accountLogin.errorMessage': 'Неверный Email или пароль',
  'pages.login.username.placeholder': 'Email',
  'pages.login.username.required': 'Введите Email',
  'pages.login.password.placeholder': 'Пароль',
  'pages.login.password.required': 'Введите пароль',
  'pages.login.forgotPassword': 'Забыли пароль?',
  'pages.login.submit': 'Отправить',

  // Project
  'formProject.title.label': 'Аталышы',
  'formProject.title.required': 'Название обязательно',
  'formProject.shortDescription.label': 'Кыскача сүрөттөмө',
  'formProject.shortDescription.required': 'Краткое описание обязательно',
  'formProject.description.label': 'Описание',
  'formProject.description.required': 'Описание обязательно',
  'formProject.tags.label': 'Теги',
  'formProject.tags.required': 'Теги обязательны',
  'formProject.status.label': 'Статус',
  'formProject.status.required': 'Статус обязателен',
  'formProject.executor.label': 'Аткаруучу',
  'formProject.executor.required': 'Исполнитель обязателен',
  'formProject.coordinator.label': 'Координатор',
  'formProject.coordinator.required': 'Координатор обязателен',
  'formProject.donors.label': 'Донорлор',
  'formProject.donors.required': 'Доноры обязательны',
  'formProject.payers.label': 'Төлөөчүлөр',
  'formProject.payers.required': 'Плательщики обязательны',
  'formProject.helpTypes.label': 'Типы Помощи',
  'formProject.helpTypes.required': 'Типы Помощи обязательны',
  'formProject.coates.label': 'Калктуу пункт',
  'formProject.coates.required': 'Населенные пункты обязательны',
  'formProject.sectors.label': 'Секторы',
  'formProject.sectors.required': 'Секторы обязательны',
  'formProject.implTerm.label': 'Сроки реализации',
  'formProject.dateSign.label': 'Кол коюлган күн',
  'formProject.dateSign.required': 'Дата подписания обязательна',
  'formProject.dateStart.label': 'Башталыш датасы',
  'formProject.dateStart.required': 'Дата начала обязательна',
  'formProject.dateEnd.label': 'Бүткөн датасы',
  'formProject.dateEnd.required': 'Дата завершения обязательна',
  'formProject.cancel': 'Жокко чыгаруу',
  'formProject.submit': 'Тапшыруу',
};
