const proSettings = {
  navTheme: 'light',
  primaryColor: '#1890ff',
  layout: 'top',
  contentWidth: 'Fixed',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  title: 'FinAid.kg',
  pwa: false,
  iconfontUrl: '',
};
export default proSettings;
