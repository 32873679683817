import {
  fetchProjects,
  fetchProject,
  fetchProjectPlansAndExpenses,
  getProjectNews,
  getProjectNewsItem,
} from './service'

const MODEL_SCOPE = 'projects';

export const defaultPageSize = 10;

let defaultState = {
  data: [],
  total: 0,
  pageSize: 10,
  page: 1,
  mode: true,
  q: '',
  tag: '',
  item: null,
};

const Model = {
  namespace: MODEL_SCOPE,
  state: defaultState,
  effects: {
    /**fetchItems1({payload}, {call, put}) {

      const {q, tag, ...variables} = payload;

      if (q || tag) {
        const cond = [];
        if (q) {
          cond.push({title_contains: q})
        }

        if (tag) {
          cond.push({'tags.label': tag})
        }

        variables.where = {_and: cond};
      }

      const res = yield call(fetchProjects, variables);

      if (res) {
        const {data, total} = res;
        yield put({
          type: 'setState',
          payload: {
            data,
            total,
          }
        })
      }

      return null;
    },*/
    *fetchItems({payload}, {call}) {
      let addWhere = payload.params?.tags?.length ? {
        'tags.id_in': payload.params.tags.map(t => t.value)
      }: null;

      return yield call(fetchProjects, payload, addWhere, ['id', 'title', 'description', 'shortDescription'])
    },
    *fetchItem({payload: id}, {call, put}) {
      const item = yield call(fetchProject, id);
      if (item) {
        yield put({type: 'setState', payload: {item}});
      }
    },
    // *fetchRequisite({payload: id}, {call,put}){
    //   const item = yield call (fetchProjectRequisite,id);
    //   if (item){
    //     yield put({type: 'setState', payload: {item}})
    //   }
    // },
    *fetchProjectPlansAndExpenses({payload: id}, {call, put, select}) {
      const item = yield select(state => state[MODEL_SCOPE].item);
      if (item) {
        const data = yield call(fetchProjectPlansAndExpenses, id);
        if (data) {
          yield put({type: 'setState', payload: {item: {...item, ...data}}});
        }
      }
    },
    *fetchProjectNews({payload: tableQuery}, {call, put, select}) {
      const item = yield select(state => state[MODEL_SCOPE].item);

      if (item) {
        return yield call(getProjectNews, item.id, tableQuery);
      }
    },
  },
  reducers: {
    setState: (state, {payload}) => ({...state, ...payload}),
    reset: () => defaultState
  }
};
export default Model;
