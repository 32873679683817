import {
  gqlCreateEntry,
  gqlDeleteEntry,
  gqlQueryEntry,
  gqlQueryTable,
  gqlUpdateEntry,
} from '@/utils/graphql-client'
import gql from 'graphql-tag';

export async function fetchTags(tableQuery) {
  const query = gql`
    query($limit: Int, $start: Int, $sort: String, $where: JSON) {
      data: tags(limit: $limit, start: $start, sort: $sort, where: $where) {
        id, label, label_en, label_ky
      }
      total: tagsConnection(where: $where) {
        aggregate {count}
      }
    }
  `;
  return gqlQueryTable(query, tableQuery);
}

export async function fetchTag(id) {
  const query = gql`
    query($id: ID!) {
      entry: tag(id: $id) {
        id, label, label_en, label_ky
      }
    }
  `;
  return gqlQueryEntry(query, id);
}

export async function updateTag(id, data) {
  const mutation = gql`
    mutation($input: updateTagInput) {
      res: updateTag(input: $input) {
        entry: tag {id}
      }
    }
  `;

  return gqlUpdateEntry(mutation, id, data);
}

export async function createTag(data) {
  const mutation = gql`
    mutation($input: createTagInput) {
      res: createTag(input: $input) {
        entry: tag {id}
      }
    }
  `;

  return gqlCreateEntry(mutation, data)
}

export async function deleteTag(id) {
  const mutation = gql`
    mutation($input: deleteTagInput) {
      res: deleteTag(input: $input) {
        entry: tag {id}
      }
    }
  `;

  return gqlDeleteEntry(mutation, id);
}
