export default {
  'dirs.title': 'Реестрлер',
  'dirs.add New': 'Жаны кошуу',
  'dirs.modal add.title': 'Добавить/редактировать Реестр',

  'formDir.name.label': 'Name',
  'formDir.name.required': 'Name is required',
  'formDir.title.label': 'Title',
  'formDir.title.required': 'Title is required',
  'formDir.description.label': 'Description',
  'formDir.description.required': 'Description is required',
  'formDir.external.label': 'External',
  'formDir.submit': 'Submit',
  'formDir.cancel': 'Cancel',
};
