import gql from "graphql-tag";
import {gqlQueryTable} from "@/utils/graphql-client";

export async function fetchOrgs(tableQuery, addWhere) {
  const query = gql`
    query($limit: Int, $start: Int, $sort: String, $where: JSON) {
      data: organizations(limit: $limit, start: $start, sort: $sort, where: $where) {
        # id, title, description, type, inn, published_at, updated_at
        # donoredProjects {
        #   sectors {
        #     title
        #   }
        # }

        id, title, description, type, inn
        title_en, title_ky, description_en, description_ky
        logo {
          name
          url
          formats
        }
      }
      total: organizationsConnection(where: $where) {
        aggregate {count}
      }
    }
  `;
  return gqlQueryTable(query, tableQuery, addWhere);
}
