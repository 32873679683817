export default {
  'formOrg.inn.label': 'INN',
  'formOrg.inn.required': 'INN is required',
  'formOrg.title.label': 'Title',
  'formOrg.title.required': 'Title is required',
  'formOrg.description.label': 'Description',
  'formOrg.is_donor.label': 'Is Donor',
  'formOrg.logo.label': 'Logo',
  'formOrg.cancel': 'Cancel',
  'formOrg.submit': 'Save',
};
