import {
  gqlDeleteEntry,
  gqlQuery,
  gqlQueryEntry,
  gqlQueryForSelect,
  gqlQueryTable,
  saveEntriesGen,
} from '@/utils/graphql-client'
import gql from 'graphql-tag';
import moment from 'moment'

/*export async function fetchProjects(variables) {
  const query = gql`
    query($limit: Int, $start: Int, $sort: String, $where: JSON) {
      data: projects(limit: $limit, start: $start, sort: $sort, where: $where) {
        id, title, shortDescription, type, status
        dateStart
        # dateEnd, dateSign, dateUpdated
        tags {id, label}
        executor {
          id, title
          logo {formats}
        }
      }
      total: projectsConnection(where: $where) {
        aggregate {count}
      }
    }
  `;
  return gqlQuery(query, variables, {
    data: 'data',
    total: 'total.aggregate.count',
  });
}*/

export async function fetchProjects(tableQuery, addWhere, searchFields) {
  const query = gql`
    query($limit: Int, $start: Int, $sort: String, $where: JSON) {
      data: projects(limit: $limit, start: $start, sort: $sort, where: $where) {
        id, title, shortDescription, type, status {
          title
        }
        dateStart, dateEnd, dateSign, dateUpdated
      }
      total: projectsConnection(where: $where) {
        aggregate {count}
      }
    }
  `;
  return gqlQueryTable(query, tableQuery, addWhere, searchFields);
}

export async function fetchProject(id) {
  const query = gql`
    query($id: ID!) {
      entry: project(id: $id) {
        id, title, shortDescription, description, type, dateStart, dateEnd, dateSign
        title_en, shortDescription_en, description_en
        title_ky, shortDescription_ky, description_ky
        status {title, title_en, title_ky}
        tags {id, label, label_en, label_ky}
        donors {id, title, title_en, title_ky}
        payers {id, title, title_en, title_ky}
        executor {id, title, title_en, title_ky}
        coordinator {id, title, title_en, title_ky}
        coates {id, title}
        sectors {id, title, title_en, title_ky}
        helpTypes {id, title, title_en, title_ky}
        goals(sort: "order") {
          id, title, order, dateStart, dateEnd
          tasks(sort: "order") {
            id, title, order, dateStart, dateEnd
            actions(sort: "order") {
              id, title, order, dateStart, dateEnd
            }
          }
        }
        sources(sort: "order") {
          key: id, title, amount, currency {title}, bank {title}
        }
        reports(sort: "order") {
          key: id, title, deadline, note, type {title}
        }
        payments(sort: "order") {
          key: id, date, results, amount, currency {title}
        }
        plans(sort: "order") {
          id, key: id, title, amountPlan, amountFact, amountRest
        }
        expenses(sort: "order") {
          id, key: id, amount, description, planItem {label: title, value: id}
          payer {value: id, label: title}
          payerAccount, payerBank {label: title, value: id}
          recipientName, recipientAccount, recipientBank {label: title, value: id}
        }
        risks(sort: "order") {
          id, key: id, title, signific, actions
        }
        expects(sort: "order") {
          id, product, indicator, result, task {title}
        }
        results(sort: "order") {
          key: id, id,
          result
          task {value: id, label: title}
          file {
            name, url,
            # formats, size, previewUrl
          }
          files {
            name, url
          }
        }
        indicators(sort: "order") {
          key: id, id,
          result
          indicator {
            value: id, label: indicator,
            indicator, result
          }
        }
        requisite {
          country region city index street house
          apt phone email bankCountry bank bik account
        }

      }
    }
  `;
  const item = await gqlQueryEntry(query, id);

  if (item) {
    item.dateSign = item.dateSign ? moment(item.dateSign) : null;
    item.dateStart = item.dateStart ? moment(item.dateStart) : null;
    item.dateEnd = item.dateEnd ? moment(item.dateEnd) : null;

    item.results.forEach((r) => {
      r.foobar = []
    })
  }

  return item;
}

export async function fetchProjectPlansAndExpenses(id) {
  const query = gql`
    query($id: ID!) {
      entry: project(id: $id) {
        plans(sort: "id") {
          id, key: id, title, amountPlan, amountFact, amountRest
        }
        expenses(sort: "id") {
          id, key: id, amount, description, planItem {label: title, value: id}
          payer {label: title, value: id}
          payerAccount, payerBank {label: title, value: id}
          recipientName, recipientAccount, recipientBank {label: title, value: id}
        }
      }
    }
  `;
  return await gqlQueryEntry(query, id);
}

export async function fetchPlanItems(variables) {
  const query = gql`
    query($limit: Int, $start: Int, $sort: String, $where: JSON) {
      data: projectPlanItems(limit: $limit, start: $start, sort: $sort, where: $where) {
        value: id, label: title
      }
      total: projectPlanItemsConnection(where: $where) {
        aggregate {count}
      }
    }
  `;

  return gqlQueryForSelect(query, variables);
}

export function getProjectNews(id, tableQuery) {
  const query = gql`
    query($limit: Int, $start: Int, $sort: String, $where: JSON) {
      data: projectNewsItems(limit: $limit, start: $start, sort: $sort, where: $where) {
        id, id, title, title_en, title_ky, published_at, updated_at
      }
      total: projectNewsItemsConnection(where: $where) {
        aggregate {count}
      }
    }
  `;
  const addWhere = {project: {id}};
  return gqlQueryTable(query, tableQuery, addWhere);
}

export async function getProjectNewsItem(id) {
  const query = gql`
    query($id: ID!) {
      entry: projectNewsItem(id: $id){
        title, description, published_at, updated_at
        title_en, description_en
        title_ky, description_ky
        # avatar {id name url mime formats}
        images {id name url formats}
        files {id name url mime formats}
      }
    }`

  let r = await gqlQueryEntry(query, id);

  r.published_at = r.published_at ? moment(r.published_at): null;
  r.updated_at = r.published_at ? moment(r.updated_at): null;

  return r;
}
