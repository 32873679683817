export default {
  'formOrg.inn.label': 'ИНН',
  'formOrg.inn.required': 'ИНН обязательно',
  'formOrg.title.label': 'Название',
  'formOrg.title.required': 'Название обязательно',
  'formOrg.description.label': 'Описание',
  'formOrg.is_donor.label': 'Донор',
  'formOrg.logo.label': 'Лого',
  'formOrg.cancel': 'Отмена',
  'formOrg.submit': 'Сохранить',
};
