export default {
  'projectDetails.title': 'Edit Project Details',
  'projectDetails.submit': 'Save',
  'projectDetails.success': 'Updated successfully',
  //News
  'news.Notifications': 'Notifications from the Administrator',
  'news.News': 'News',
  'news.Instructions': 'Instructions',
  'news.QuestionsAnswers': 'Questions and Answers',
  'news.Add New': 'Create',

  //Users
  'users.Add' : 'Add',
  //All translate
  'projectView.Add': 'Add',
  'projectView.Delete': 'Delete',
  'projectView.Edit': 'Edit',
  'formProject.implementation.label': 'Terms of implementation',

  'projectDetails.goals.Goals': 'Goals',
  'projectDetails.goals.Tasks': 'Tasks',
  'projectDetails.goals.Actions': 'Actions',
  'projectDetails.goals.Save': 'Save',
  'projectDetails.goals.Cancel': 'Cancel',
  'projectDetails.goals.Move Up': 'Move Up',
  'projectDetails.goals.Move Down': 'Move Down',
  'projectDetails.goals.Edit': 'Edit',
  'projectDetails.goals.Delete': 'Delete',
  'projectDetails.goals.Add Goal': 'Add Goal',
  'projectDetails.goals.Add Task': 'Add Task',
  'projectDetails.goals.Add Action': 'Add Action',

  'projectDetails.sources.Add': 'Add',
  'projectDetails.sources.Bank': 'Bank',
  'projectDetails.sources.Amount': 'Amount',
  'projectDetails.sources.Currency': 'Currency',
};
