export default {
  //Projects
  'news.News': 'Жанылыктар',
  'project.Donors': 'Донорлор',
  'projects.Projects': 'Долбоорлор',
  'projects.Add New': 'Жаны кошуу',
  //ProjectView
  'projectView.Expenses': 'Чыгымдар',

  'projectView.Plan Budget': 'Бюджет',
  'projectView.about': 'Проект жөнүндө',
  'projectView.create': 'Кошуу',
  'projectView.plan': 'Чыгымдар планы',
  'projectView.results': 'Жыйынтыктар',
  'projectView.news': 'Жаңылыктар жана басылмалар',
  'projectView.messages': 'Сообщения',
  'projectView.edit': 'Түзөтүү',
  'projectView.project': 'Долбоор',
  'projectView.requisites': 'Реквизиты',//Реквизиты
  'projectView.chronology': 'Окуя хронологиясы',//Реквизиты
  'projectView.Messages': 'Администратордун эскертмелери',
  'projectView.News': 'Жанылыктар',
  'projectView.QuizAndFaq': 'Суроолор жана Жооптор',
  'projectView.Instruc': 'Нускамалар',
  'projectView.tags': 'Тегдер',
  'projectView.description': 'Сүрөттөмө',
  'projectView.dateStart': 'Дата Начала',
  'projectView.dateEnd': 'Башталган күнү',
  'projectView.dateSign': 'Кол коюлган күн',
  'projectView.donors': 'Донорлор',
  'projectView.helpTypes': 'Типы Помощи',
  'projectView.payers': 'Плательщики',
  'projectView.executor': 'Аткаруучу',
  'projectView.coordinator': 'Координатор',
  'projectView.coates': 'Населённые пункты',
  'projectView.sectors': 'Секторлор',
  'projectView.country': 'Өлкө',
  'projectView.region': 'Район, облусу',
  'projectView.city': 'Шаар, айыл',
  'projectView.index': 'Индекс',
  'projectView.street': 'Көчөсү',
  'projectView.homeNum': 'Уй №',
  'projectView.apartment': 'Кв.',
  'projectView.tel': 'Тел.',
  'projectView.email': 'Эл.дарек',
  'projectView BankName': 'Банктын аталышы',
  'projectView Bik': 'БИК',
  'projectView Address': 'Адресс',
  'projectView BankData': 'Банк маалыматтары',
  'projectView CheckingAccount': 'Эсептешүү эсеби',
  'projectView.activity': 'Аракет',
  'projectView.goals': 'Максаттар жана тапшырмалар',
  'projectView.sources': 'Каржылоо булактары',
  'projectView.reports': 'Долбоордун отчеттору',
  'projectView.payouts': 'Төлөм графиги',
  'projectView.expectations': 'Күтүлгөн жыйынтыктар',
  'projectView.risks': 'Тобокелдиктер',

  'projectView.goals.dateFrom': 'С',
  'projectView.goals.dateTo': 'По'

};
