export default {
  //Dirs Entries
  'entries.Dirs': 'Справочник',
  //News
  'news.Notifications': 'Уведомления от Администратора',
  'news.News': 'Новости',
  'news.Instructions': 'Инструкции',
  'news.QuestionsAnswers': 'Вопросы и Ответы',
  'news.Add New': 'Создать',
  //Users
  'users.Add' : 'Добавить',
  //All Translate
  'projectView.Add': 'Добавить',
  'projectView.Delete': 'удалить',
  'projectView.Edit': 'редактировать',
  'formProject.implementation.label.label': 'Сроки реализации',

  'projectDetails.title': 'Редактировать Детали Проекта',
  'projectDetails.submit': 'Сохранить',
  'projectDetails.success': 'Успешно сохранён',

  'projectDetails.goals.Goals': 'Цели',
  'projectDetails.goals.Tasks': 'Задачи',
  'projectDetails.goals.Actions': 'Действия',
  'projectDetails.goals.Save': 'Сохранить',
  'projectDetails.goals.Cancel': 'Отмена',
  'projectDetails.goals.Move Up': 'Переместить вверх',
  'projectDetails.goals.Move Down': 'Переместить вниз',
  'projectDetails.goals.Edit': 'Редактировать',
  'projectDetails.goals.Delete': 'Удалить',
  'projectDetails.goals.Add Goal': 'Добавить цель',
  'projectDetails.goals.Add Task': 'Добавить задачу',
  'projectDetails.goals.Add Action': 'Добавить действие',

  'projectDetails.sources.Add': 'Добавить',
  'projectDetails.sources.Bank': 'Банк',
  'projectDetails.sources.Amount': 'Сумма',
  'projectDetails.sources.Currency': 'Валюта',
};
