import {
  gqlCreateEntry,
  gqlDeleteEntry, gqlQuery,
  gqlQueryEntry,
  gqlQueryTable,
  gqlUpdateEntry,
} from '@/utils/graphql-client'
import gql from 'graphql-tag';
import {notification} from 'antd'

export async function fetchDir(name) {
  if (!name) {
    console.error('name required')
    notification.error({message: 'name required'});
    return null;
  }

  const query = gql`
    query($where: JSON){
      dirs(where: $where) {
        id, title, description, name, external
        title_en, title_ky, description_en, description_ky
      }
    }
  `;
  return gqlQuery(query, {where: {name}}, 'dirs[0]');
}

export async function fetchDirEntries(tableQuery, dirId) {
  const fetchDirEntriesQuery = gql`
    query($limit: Int, $start: Int, $sort: String, $where: JSON) {
      data: dirEntries(limit: $limit, start: $start, sort: $sort, where: $where) {
        id, title, title_en, title_ky
      }
      total: dirEntriesConnection(where: $where) {
        aggregate {count}
      }
    }
  `;
  const addWhere = {dir: {id: dirId}};
  return gqlQueryTable(fetchDirEntriesQuery, tableQuery, addWhere);
}

export async function fetchDirEntry(id) {
  const query = gql`
    query($id: ID!) {
      entry: dirEntry(id: $id) {
        id, title
      }
    }
  `;
  return gqlQueryEntry(query, id);
}

export async function createDirEntry(data) {
  const mutation = gql`
    mutation($input: createDirEntryInput) {
      res: createDirEntry(input: $input) {
        entry: dirEntry {
          id
        }
      }
    }
  `;

  return gqlCreateEntry(mutation, data)
}

export async function updateDirEntry(id, data) {
  const mutation = gql`
    mutation($input: updateDirEntryInput) {
      res: updateDirEntry(input: $input) {
        entry: dirEntry {
          id
        }
      }
    }
  `;

  return gqlUpdateEntry(mutation, id, data)
}

export async function deleteDirEntry(id) {
  const mutation = gql`
    mutation($input: deleteDirEntryInput) {
      res: deleteDirEntry(input: $input) {
        entry: dirEntry {
          id
        }
      }
    }
  `;

  return gqlDeleteEntry(mutation, id)
}
