import {
  gqlCreateEntry,
  gqlDeleteEntry, gqlMutate,
  gqlQueryEntry,
  gqlQueryTable,
  gqlUpdateEntry,
} from '@/utils/graphql-client'
import gql from 'graphql-tag';

/*
title
description
type
coordinatedProjects
executedProjects
inn
donoredProjects
payers
partnerProjects
*/

export async function fetchOrgs(tableQuery) {
  const query = gql`
    query($limit: Int, $start: Int, $sort: String, $where: JSON) {
      data: organizations(limit: $limit, start: $start, sort: $sort, where: $where) {
        id, title, description, type, inn
        title_en, title_ky, description_en, description_ky
        logo {
          name
          url
          formats
        }
      }
      total: organizationsConnection(where: $where) {
        aggregate {count}
      }
    }
  `;
  return gqlQueryTable(query, tableQuery);
}

export async function fetchOrg(id) {
  const query = gql`
    query($id: ID!) {
      entry: organization(id: $id) {
        id, title, description, type, inn
        title_en, title_ky, description_en, description_ky
        is_donor
        logo {
          name
          url
          formats
        }
      }
    }
  `;
  return gqlQueryEntry(query, id);
}

export async function updateOrg(id, data) {
  const mutation = gql`
    mutation($input: updateOrganizationInput) {
      res: updateOrganization(input: $input) {
        entry: organization {id}
      }
    }
  `;

  return gqlUpdateEntry(mutation, id, data);
}

export async function createOrg(data) {
  const mutation = gql`
    mutation($input: createOrganizationInput) {
      res: createOrganization(input: $input) {
        entry: organization {id}
      }
    }
  `;

  return gqlCreateEntry(mutation, data)
}

export async function deleteOrg(id) {
  const mutation = gql`
    mutation($input: deleteOrganizationInput) {
      res: deleteOrganization(input: $input) {
        entry: organization {id}
      }
    }
  `;

  return gqlDeleteEntry(mutation, id);
}


export async function uploadOrgLogo(variables) {
  // FileInfoInput
  // name: String
  // alternativeText: String
  // caption: String

  const mutation = gql`
    mutation($refId: ID, $info: FileInfoInput, $file: Upload!) {
      res: upload(ref: "organization", field: "logo", refId: $refId, info: $info, file: $file) {
        id
      }
    }
  `;

  // debugger
  return gqlMutate(mutation, variables, 'res.id');
}
