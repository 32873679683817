export default {
  'table.Title':'Title',
  'table.Published':'Published',
  'table.Project':'Project',

  'table.Payer': 'Payer',
  'table.PayerAccount': 'PayerAccount',
  'table.PayerBank': 'PayerBank',
  'table.RecipientName': 'RecipientName',
  'table.RecipientAccount': 'Recipient of Account',
  'table.RecipientBank': 'Recipient of Bank',
  'table.AssignmentPayment': 'Purpose of payment',

  'table.Edit': 'Edit',
  'table.Delete': 'Delete',
  'table.Role': 'Role',
  'table.Created': 'Created',
  'table.UserName': 'User Name',
  'table.Subscribe': 'Subscribe',
  'table.Updated': 'Updated',
  'table.Type': 'Type',
  'table.Status': 'Status',
  'table.Action': 'Actions',
  'projects.Projects': 'Projects',
  'projects.Add New': 'Add New',
  'table.Details': 'Details',
  'table.Name': 'Name',
  'table.KRDescription': 'Short desc.',
  'table.Start': 'Start',
  'table.Finish': 'Finish',
  'table.Done': 'Done',
  'table.File': 'File',
  'table.AmountPlan': 'Plan',
  'table.AmountFact': 'Fact',
  'table.Create': 'Create',
  'table.Save': 'Save',
  'table.AmountRemainder': 'Remainder',
  'table.Risk': 'Risk',
  'table.ImportanceRisk': 'Importance of Risk',
  'table.Task': 'Task',
  'table.Product': 'Product',
  'table.Indicators': 'Indicators',
  'table.PaymentDate': 'PaymentDate',
  'table.Results': 'Results',
  'table.TypeReport': 'TypeReport',
  'table.Deadlines': 'Deadlines',
  'table.Notation': 'Notation',
  'table.Bank': 'Bank',
  'table.Sum': 'Amount',
  'table.Currency': 'Currency',

  'home.news': 'News',
  'home.news Publications': 'News and Publications',

  'pages.login.accountLogin.tab': 'Account Login',
  'pages.login.accountLogin.errorMessage': 'Incorrect username/password',
  'pages.login.username.placeholder': 'Email',
  'pages.login.username.required': 'Please input your username!',
  'pages.login.password.placeholder': 'Password',
  'pages.login.password.required': 'Please input your password!',
  'pages.login.forgotPassword': 'Forgot Password ?',
  'pages.login.submit': 'Submit',

  'formProject.title.label': 'Title',
  'formProject.title.required': 'Title is required',
  'formProject.shortDescription.label': 'Short Description',
  'formProject.shortDescription.required': 'Short Description is required',
  'formProject.description.label': 'Description',
  'formProject.description.required': 'Description is required',
  'formProject.tags.label': 'Tags',
  'formProject.tags.required': 'Tags is required',
  'formProject.status.label': 'Status',
  'formProject.status.required': 'Status is required',
  'formProject.executor.label': 'Executor',
  'formProject.executor.required': 'Executor is required',
  'formProject.coordinator.label': 'Coordinator',
  'formProject.coordinator.required': 'Coordinator is required',
  'formProject.donors.label': 'Donors',
  'formProject.donors.required': 'Donors is required',
  'formProject.payers.label': 'Payers',
  'formProject.payers.required': 'Payers is required',
  'formProject.helpTypes.label': 'Types of Help',
  'formProject.helpTypes.required': 'Types of Help is required',
  'formProject.coates.label': 'Coates',
  'formProject.coates.required': 'Coates is required',
  'formProject.sectors.label': 'Sectors',
  'formProject.sectors.required': 'Sectors is required',
  'formProject.implTerm.label': 'Implementation Terms',
  'formProject.dateSign.label': 'Date of Sign',
  'formProject.dateSign.required': 'Date of Sign is required',
  'formProject.dateStart.label': 'Date of Start',
  'formProject.dateStart.required': 'Date of Start is required',
  'formProject.dateEnd.label': 'Date of End',
  'formProject.dateEnd.required': 'Date of End is required',
  'formProject.cancel': 'Cancel',
  'formProject.submit': 'Submit',
};
