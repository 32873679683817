export default {
  //Projects
  'project.Donors': 'Donors',
  'projectView.Expenses': 'Expenses',
  'projectView.country': 'Country',
  'projectView.region': 'Region',
  'projectView.city': 'City, village',
  'projectView.index': 'Index',
  'projectView.street': 'Street',
  'projectView.homeNum': 'Home №',
  'projectView.apartment': 'Apartment',
  'projectView.tel': 'Tel.',
  'projectView.email': 'Email',
  'projectView BankName': 'BankName',
  'projectView Bik': 'BIK',
  'projectView CheckingAccount': 'Checking Account',
  'projectView BankData': 'Bank Data',
  'projectView Address': 'Address',
  'projectView.Plan Budget': 'Budget',

  'news.News':'News',
  'projectView.create': 'Create',
  'projectView.project':'Project',
  'projectView.requisites':'Requisites',
  'projectView.chronology':'Chronology',
  'projectView.about': 'About Project',
  'projectView.plan': 'Spending Plans',
  'projectView.results': 'Results',
  'projectView.news': 'News and Publications',
  'projectView.messages': 'Messages',
  'projectView.edit': 'Edit',

  'projectView.Messages': 'Messages',
  'projectView.News': 'News',
  'projectView.QuizAndFaq': 'Questions and Answers',
  'projectView.Instruc': 'Instructions',

  'projectView.tags': 'Tags',
  'projectView.description': 'Description',
  'projectView.dateStart': 'Date of Start',
  'projectView.dateEnd': 'Date of End',
  'projectView.dateSign': 'Date of Sign',
  'projectView.donors': 'Donors',
  'projectView.helpTypes': 'Types of Help',
  'projectView.payers': 'Payers',
  'projectView.executor': 'Executor',
  'projectView.coordinator': 'Coordinator',
  'projectView.coates': 'Coates',
  'projectView.sectors': 'Sectors',

  'projectView.activity': 'Activity',
  'projectView.goals': 'Goals and Tasks',
  'projectView.sources': 'Payment sources',
  'projectView.reports': 'Reports',
  'projectView.payouts': 'Payouts',
  'projectView.expectations': 'Expectations',
  'projectView.risks': 'Risks',

  'projectView.goals.dateFrom': 'From',
  'projectView.goals.dateTo': 'To'

};
