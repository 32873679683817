export default {
  'formDir.name.label': 'Name',
  'formDir.name.required': 'Name is required',
  'formDir.title.label': 'Title',
  'formDir.title.required': 'Title is required',
  'formDir.description.label': 'Description',
  'formDir.description.required': 'Description is required',
  'formDir.external.label': 'External',
  'formDir.submit': 'Submit',
  'formDir.cancel': 'Cancel',
};
