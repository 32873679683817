import {notification} from 'antd';
import {
  createProject,
  deleteProject,
  fetchProject,
  fetchProjectDetails,
  fetchProjects,
  fetchProjectRequisite,
  saveProjectDetails,
  updateProject,
  saveProjectRequisite,
} from './service';

const MODEL_SCOPE = 'adminProjects';

const Model = {
  namespace: MODEL_SCOPE,
  state: {
    editItem: null
  },
  effects: {
    *fetchItems({payload}, {call}) {
      const res = yield call(fetchProjects, payload);

      if (res) {
        const {data, total} = res;
        return {
          data,
          total,
          success: true
        }
      }

      return null;
    },
    *fetchItem({payload: id}, {call, put}) {
      const item = yield call(fetchProject, id);
      if (item) {
        yield put({type: 'setState', payload: {item}});
      }
    },
    *fetchItemDetails({payload: id}, {call, put}) {
      const item = yield call(fetchProjectDetails, id);
      if (item) {
        yield put({type: 'setState', payload: {item}});
      }
    },
    *fetchRequisite({payload: id}, {call,put}){
      const item = yield call (fetchProjectRequisite,id);
      if (item){
        yield put({type: 'setState', payload: {item}})
      }
    },
    *saveItemDetails({payload}, {call, put}) {
      yield call(saveProjectDetails, payload)
    },
    *setEditItem({payload: id}, {call, put}) {
      if (id === null) {
        yield put({type: 'setStateEditItem', payload: null});

      } else if (id === 'new') {
        const item = {
          id: null, title: ''
        }
        yield put({type: 'setStateEditItem', payload: item});

      } else {
        const item = yield call(fetchProject, id);

        if (item) {
          yield put({type: 'setStateEditItem', payload: item});
        }
      }
    },
    *setEditReq({payload: id},{call,put}){
      if (id === null) {
        yield put({type: 'setStateEditItem', payload: null});

      } else {
        const item = yield call(fetchProjectRequisite, id);

        if (item) {
          yield put({type: 'setStateEditItem', payload: item});
        }
      }
    },
    *saveRequisite({payload}, {call, select}) {
      const projectId = yield select(state => state[MODEL_SCOPE].editItem?.id);
      return yield call(saveProjectRequisite, projectId, payload)
    },
    *saveItem({payload}, {call, select}) {
      const {id, ...data} = payload;

      if (id) {
        const res = yield call(updateProject, id, data);
        if (res) {
          notification.success({message: 'Updated successfully'});
          return true;
        }
      } else {
        const dirId = yield select(state => state[MODEL_SCOPE].dirId);
        const res = yield call(createProject, {...data, dir: dirId});
        if (res) {
          notification.success({message: 'Created successfully'});
          return true;
        }
      }

      return false;
    },
    *deleteItem({payload: id}, {call}) {
      const res = yield call(deleteProject, id);
      if (res) {
        notification.success({message: 'Deleted successfully'});
        return true;
      }
      return false;
    },
  },
  reducers: {
    setState(state, {payload}) {
      return{...state, ...payload}
    },
    setStateEditItem(state, {payload: editItem}) {
      return {...state, editItem};
    },
  }
};
export default Model;
