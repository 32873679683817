import {notification} from 'antd';
import {
  createOrg,
  deleteOrg,
  fetchOrg,
  fetchOrgs,
  updateOrg,
  uploadOrgLogo,
} from './service';
import {getFileUrl} from '@/utils/utils'

const MODEL_SCOPE = 'adminOrgs';

const Model = {
  namespace: MODEL_SCOPE,
  state: {
    editItem: null
  },
  effects: {
    *fetchItems({payload}, {call}) {
      const res = yield call(fetchOrgs, payload);

      if (res) {
        const {data, total} = res;
        return {
          data,
          total,
          success: true
        }
      }

      return null;
    },
    *setEditItem({payload: id}, {call, put}) {
      if (id === null) {
        yield put({type: 'setStateEditItem', payload: null});

      } else if (id === 'new') {
        const item = {
          id: null, title: ''
        }

        yield put({type: 'setStateEditItem', payload: item});

      } else {
        const item = yield call(fetchOrg, id);

        if (item) {
          const {logo} = item;

          if (logo) {
            item.logo = [{
              uid: '-1',
              name: logo.name,
              status: 'done',
              url: getFileUrl(logo.url),
              thumbUrl: getFileUrl(logo.formats.thumbnail.url),
            }]
          }

          yield put({type: 'setStateEditItem', payload: item});
        }
      }
    },
    *saveItem({payload}, {call, select}) {
      const {id, logo, ...data} = payload;

      if (!logo?.[0])
        data.logo = null;

      let res;
      if (id) {
        res = yield call(updateOrg, id, data);
        if (res) {
          notification.success({message: 'Updated successfully'});
        }
      } else {
        const dirId = yield select(state => state[MODEL_SCOPE].dirId);
        res = yield call(createOrg, {...data, dir: dirId});
        if (res) {
          notification.success({message: 'Created successfully'});
        }
      }

      if (!res) {
        return false
      }

      if (logo && logo.length > 0) {
        const logo0 = logo[0];
        if (logo0.originFileObj) {
          const file = logo0.originFileObj;
          const {name} = logo0;

          const uploadLogo = {
            refId: res,
            file,
            info: {
              name
            }
          };

          const fileRes = yield call(uploadOrgLogo, uploadLogo);
          if (!fileRes) {
            return false
          }

          // TODO: remove old file, strapi graphql doesn't support it

          notification.success({message: 'Logo uploaded successfully'});
        }
      }


      return true
    },
    *deleteItem({payload: id}, {call}) {
      const res = yield call(deleteOrg, id);
      if (res) {
        notification.success({message: 'Deleted successfully'});
        return true;
      }
      return false;
    },
  },
  reducers: {
    setStateEditItem(state, {payload: editItem}) {
      return {...state, editItem};
    },
  }
};
export default Model;
