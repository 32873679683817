export default {
  //Dirs && Entries
  // 'entries.Dirs': 'Маалымдама',
  //News
  'news.Notifications': 'Администратордун Билдирмелери',
  'news.News': 'Жанылыктар',
  'news.Instructions': 'Нускамалар',
  'news.QuestionsAnswers': 'Cуроолор жана Жооптор',
  'news.Add New': 'Жаны кошуу',
  //Users
  'users.Add': 'Кошуу',
  //All Translate
  'projectView.Add': 'Кошуу',
  'formProject.implementation.label': 'Ишке ашыруу убактысы',
  'projectDetails.title': 'Долбоордун түзүмүн өзгөртүү',//Редактировать Детали Проекта
  'projectDetails.submit': 'Сактоо',
  'projectDetails.success': 'Ийгиликтүү сакталды',
  'projectDetails.goals.Goals': 'Максаттар',
  'projectDetails.goals.Tasks': 'Тапшырмалар',
  'projectDetails.goals.Actions': 'Иш-аракеттер',
  'projectDetails.goals.Save': 'Сактоо',
  'projectDetails.goals.Cancel': 'Кайтаруу',//Отмена
  'projectDetails.goals.Move Up': 'Өйдө жылдуруу',//Переместить вверх
  'projectDetails.goals.Move Down': 'Ылдый жылдыруу',
  'projectDetails.goals.Edit': 'Түзөтүү',
  'projectDetails.goals.Delete': 'Өчүрүү',
  'projectDetails.goals.Add Goal': 'Максат кошуу',//Добавить цель
  'projectDetails.goals.Add Task': 'Тапшырманы кошуу',
  'projectDetails.goals.Add Action': 'Иш-аракет кошуу',//Добавить действие

  'projectDetails.sources.Add': 'Добавить',
  'projectDetails.sources.Bank': 'Банк',
  'projectDetails.sources.Amount': 'Сумма',
  'projectDetails.sources.Currency': 'Валюта',
};
