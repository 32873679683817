import global from './ky-KG/global';
import menu from './ky-KG/menu';
// import pwa from './ky-KG/pwa';
// import settingDrawer from './ky-KG/settingDrawer';
// import settings from './ky-KG/settings';
import pages from './ky-KG/pages';

export default {
  // 'navBar.lang': 'Languages',
  // 'layout.user.link.help': 'Help',
  // 'layout.user.link.privacy': 'Privacy',
  // 'layout.user.link.terms': 'Terms',
  // 'app.preview.down.block': 'Download this page to your local project',
  // 'app.welcome.link.fetch-blocks': 'Get all block',
  // 'app.welcome.link.block-list': 'Quickly build standard, pages based on `block` development',
  ...global,
  ...menu,
  // ...settingDrawer,
  // ...settings,
  // ...pwa,
  ...pages,
};
