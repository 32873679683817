import {
  fetchOrgs,
} from './service';

const MODEL_SCOPE = 'donors';

const Model = {
  namespace: MODEL_SCOPE,
  state: {
    editItem: null
  },
  effects: {
    *fetchItems({payload}, {call}) {
      return yield call(fetchOrgs, payload, {is_donor: true});
    },
  },
  reducers: {
  }
};
export default Model;
