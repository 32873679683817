export default {
  'table.Title': 'Название',
  'table.Published': 'Опубликовано',
  'table.Project': 'Проект',
  'table.Payer': 'Плательщик',
  'table.PayerAccount': 'Счет Пл.',
  'table.PayerBank': 'Банк Пл.',
  'table.RecipientName': 'Получатель',
  'table.RecipientAccount': 'Счет Пол.',
  'table.RecipientBank': 'Банк пол.',
  'table.AssignmentPayment': 'Назначение платежа',
  'table.AmountPlan': 'План',
  'table.AmountFact': 'Факт',
  'table.Create': 'Создать',
  'table.Save': 'Сохранить',
  'table.AmountRemainder': 'Остаток',
  'table.Task': 'Задача',
  'table.Risk': 'Риски',
  'table.ImportanceRisk': 'Важность риска',
  'table.Product': 'Продукты',
  'table.Indicators': 'Индикаторы',
  'table.PaymentDate': 'Дата выплаты',
  'table.Results': 'Результаты',
  'table.Bank': 'Банк',
  'table.TypeReport': 'Тип отчета',
  'table.Deadlines': 'Сроки исполнения',
  'table.Notation': 'Примечание',
  'table.Sum': 'Сумма',
  'table.Currency': 'Валюта',
  'table.Name': 'Название',
  'table.KRDescription': 'Кр.описание',
  'table.Start': 'Начало',
  'table.Done': 'Выполнено',
  'table.Finish': 'Завершение',
  'table.File': 'Файл',
  'table.Subscribe': 'Подписан',
  'table.Updated': 'Обновлено',
  'table.Type': 'Типы',
  'table.Status': 'Статус',
  'table.Action': 'Действие',
  'table.Edit': 'Редактировать',
  'table.Details': 'Подробнее',
  'table.Delete': 'Удалить',
  'table.Role': 'Роль',
  'table.UserName': 'Пользователь',
  'table.Created': 'Создан',

  //Home
  'home.news': 'Новости',
  'home.news Publications': 'Новости и Публикации',
  // 'home.project.events': 'Долбоорлор жана окуялар',
  // 'home.about.platform': 'Платформа жөнүндө',
  // 'home.projects': 'Долбоорлор',
  // 'home.donors': 'Донорлор',
  // 'home.Infographics': 'Инфографика',

  'pages.login.accountLogin.tab': 'Вход в Аккаунт',
  'pages.login.accountLogin.errorMessage': 'Неверный Email или пароль',
  'pages.login.username.placeholder': 'Email',
  'pages.login.username.required': 'Введите Email',
  'pages.login.password.placeholder': 'Пароль',
  'pages.login.password.required': 'Введите пароль',
  'pages.login.forgotPassword': 'Забыли пароль?',
  'pages.login.submit': 'Отправить',

  'formProject.title.label': 'Название',
  'formProject.title.required': 'Название обязательно',
  'formProject.shortDescription.label': 'Краткое описание',
  'formProject.shortDescription.required': 'Краткое описание обязательно',
  'formProject.description.label': 'Описание',
  'formProject.description.required': 'Описание обязательно',
  'formProject.tags.label': 'Теги',
  'formProject.tags.required': 'Теги обязательны',
  'formProject.status.label': 'Статус',
  'formProject.status.required': 'Статус обязателен',
  'formProject.executor.label': 'Исполнитель',
  'formProject.executor.required': 'Исполнитель обязателен',
  'formProject.coordinator.label': 'Координатор',
  'formProject.coordinator.required': 'Координатор обязателен',
  'formProject.donors.label': 'Доноры',
  'formProject.donors.required': 'Доноры обязательны',
  'formProject.payers.label': 'Плательщики',
  'formProject.payers.required': 'Плательщики обязательны',
  'formProject.helpTypes.label': 'Типы Помощи',
  'formProject.helpTypes.required': 'Типы Помощи обязательны',
  'formProject.coates.label': 'Населенные пункты',
  'formProject.coates.required': 'Населенные пункты обязательны',
  'formProject.sectors.label': 'Секторы',
  'formProject.sectors.required': 'Секторы обязательны',
  'formProject.implTerm.label': 'Сроки реализации',
  'formProject.dateSign.label': 'Дата подписания',
  'formProject.dateSign.required': 'Дата подписания обязательна',
  'formProject.dateStart.label': 'Дата начала',
  'formProject.dateStart.required': 'Дата начала обязательна',
  'formProject.dateEnd.label': 'Дата завершения',
  'formProject.dateEnd.required': 'Дата завершения обязательна',
  'formProject.cancel': 'Отмена',
  'formProject.submit': 'Сохранить',
};
