import {ApolloClient, InMemoryCache} from '@apollo/client';
import {entries, fromPairs, get} from 'lodash';
import {notification} from 'antd'
import {BASE_API_URL} from '../../config/contants'
import {createUploadLink} from 'apollo-upload-client';
import {setContext} from '@apollo/client/link/context';
import gql from 'graphql-tag'

const uploadLink = createUploadLink({
  uri: `${BASE_API_URL}/graphql`,
});

const getToken = () => localStorage.getItem('token');

const authLink = setContext((_, {headers, noAuth}) => {
  const token = getToken();

  return {
    headers: {
      ...headers,
      Authorization: (!noAuth && token) ? `Bearer ${token}` : "",
    },
  }

  /* query {
    role(id) {
      id
      name
      description
      type
      permissions {
        id
        type
        controller
        action
        enabled
        policy
      }
    }
  }
   */
});

const client = new ApolloClient({
  // uri: `${BASE_API_URL}/graphql`,
  cache: new InMemoryCache(),
  link: authLink.concat(uploadLink),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache'
    }
  }
});

export default client;

export function tableQueryToGqlVars(query, addWhere, searchFields) {
  // TODO: use filter
  // eslint-disable-next-line no-unused-vars
  const {params, sorter, filter} = query;
  const {current, pageSize, keyword} = params;

  let key, sort;
  if (sorter) {
    // supports only one col
    [key] = Object.keys(sorter);
    // TODO: relation col fix
    // key = key.replaceAll(',', '.')
  }
  if (key) {
    sort = `${key}:${sorter[key] === 'ascend' ? 'asc' : 'desc'}`;
  } else {
    sort = 'id';
  }

  const start = (current - 1) * pageSize;

  let where;
  if (keyword && searchFields) {
    let filter = [];
    where = {_or: filter};

    searchFields.forEach(f => {
      filter.push({[`${f}_contains`]: keyword})
    })
  }
  if (addWhere) {
    where = {
      _and: [where, addWhere]
    }
  }

  return {
    limit: pageSize,
    start,
    sort,
    where,
  };
}

function getData(data, getDataFn) {
  try {
    if (typeof getDataFn === 'string') {
      return get(data, getDataFn);
    } else if (typeof getDataFn === 'function') {
      return getDataFn(data);
    } else if (getDataFn instanceof Array) {
      return getDataFn.map(path => getData(data, path));
    } else if (typeof getDataFn === 'object') {
      return fromPairs(entries(getDataFn).map(([k, path]) => [k, getData(data, path)]))
    } else {
      return data;
    }
  } catch (e) {
    console.warn(e)
    notification.error({message: e.message || 'Error'});
    return null;
  }
}

export async function gqlQuery(query, variables, getDataFn, silent = false) {
  try {
    const res = await client.query({query, variables});
    return getData(res.data, getDataFn);

  } catch (e) {
    console.warn(e)
    if (!silent)
      notification.error({message: e.message || 'Error'});
    return null;
  }
}

export async function gqlMutate(mutation, variables, getDataFn, silent = false, context) {
  try {
    const res = await client.mutate({mutation, variables, context});
    return getData(res.data, getDataFn);

  } catch (e) {
    console.warn(e);
    debugger
    if (!silent)
      notification.error({message: e.message || 'Error'});
    // errors[0].extensions.exception.data.message[0].messages[0].message
    // errors[0].extensions.exception.data.error
    return null;
  }
}

export function gqlCreate(mutation, data, getDataFn) {
  return gqlMutate(mutation, {input: {data}}, getDataFn);
}

export function gqlUpdate(mutation, id, data, getDataFn) {
  if (!id) {
    console.error('id required');
    notification.error({message: 'id required'});
    return null;
  }

  return gqlMutate(mutation, {
    input: {
      where: {id},
      data,
    },
  }, getDataFn);
}

export function gqlDelete(mutation, id, getDataFn) {
  if (!id) {
    console.error('id required');
    notification.error({message: 'id required'});
    return null;
  }

  return gqlMutate(mutation, {
    input: {
      where: {id}
    }
  }, getDataFn);
}

export function gqlQueryTable(query, tableQuery, addWhere, searchFields) {
  const variables = tableQueryToGqlVars(tableQuery, addWhere, searchFields);
  return gqlQuery(query, variables, {
    data: 'data',
    total: 'total.aggregate.count',
    success: () => true,
  });
}

export function gqlQueryEntry(query, id) {
  return gqlQuery(query, {id}, 'entry')
}

export async function gqlQueryForSelect(query, variables) {
  let res = await gqlQuery(query, variables, {data: 'data', total: 'total.aggregate.count'});

  if (res) {
    const {data, total} = res;
    const {start} = variables;
    const more = start + data.length < total;
    return {data, more}
  }

  return null;
}

export function gqlCreateEntry(mutation, data) {
  return gqlCreate(mutation, data, 'res.entry.id')
}

export function gqlUpdateEntry(mutation, id, data) {
  // eslint-disable-next-line no-underscore-dangle,no-param-reassign
  delete data.__typename;

  return gqlUpdate(mutation, id, data, 'res.entry.id')
}

export function gqlDeleteEntry(mutation, id) {
  return gqlDelete(mutation, id, 'res.entry.id')
}

export async function saveEntriesGen(dataGenerator) {
  let items = [];
  let queries = [];
  let types = [];
  let variables = {};
  let counter = 0;

  let entries = dataGenerator();

  for (let entry of entries) {
    let {d, item, entryName, EntryName} = entry;
    let {id, _deleted} = item;
    let k = counter++;
    let t, v, q;
    EntryName ||= entryName[0].toUpperCase() + entryName.substr(1);

    items.push({item, k, entryName});

    if (_deleted && id) {
      t = `delete${EntryName}Input`;
      v = {
        where: {id},
      };
      q = `d${k}: delete${EntryName}(input: $i${k}) {${entryName} {id}}`;

    } else if (id) {
      t = `update${EntryName}Input`
      v = {
        where: {id},
        data: d,
      };
      q = `r${k}: update${EntryName}(input: $i${k}) {${entryName} {id}}`;

    } else {
      t = `create${EntryName}Input`
      v = {
        data: d,
      };
      q = `r${k}: create${EntryName}(input: $i${k}) {${entryName} {id}}`;
    }

    queries.push(q)
    types.push(`$i${k}: ${t}`)
    variables[`i${k}`] = v;
  }


  if (queries.length > 0) {
    let query = gql`
      mutation(${types.join(', ')}) {
        ${queries.join('\n')}
      }
    `;

    let res = await gqlMutate(query, variables);
    if (res) {
      items.forEach(({item, k, entryName}) => {
        item.id = res[`r${k}`]?.[entryName].id;
      });
    }
    return res;
  }
}

export function uploadFile(file, refId, ref, field) {
  if (!file instanceof File) {
    debugger
    return
  }

  const variables = {
    ref,
    refId,
    field,
    file,
    // info: {
    //   name: String
    //   alternativeText: String
    //   caption: String
    // }
  }

  const mutation = gql`
    mutation($ref: String, $field: String, $refId: ID, $info: FileInfoInput, $file: Upload!) {
      res: upload(ref: $ref, field: $field, refId: $refId, info: $info, file: $file) {
        id, url, name
      }
    }
  `;

  return gqlMutate(mutation, variables, 'res');
}
