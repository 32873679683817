export default {
  //Projects
  'project.Donors': 'Доноры',
  'projectView.Expenses': 'Расходы',
  'projectView.create': 'Создать',
  'projectView.Messages': 'Уведомления от Администратора',
  'projectView.News': 'Новости',
  'projectView.QuizAndFaq': 'Вопросы и ответы',
  'projectView.Instruc': 'Инструкции',

  'projectView.Plan Budget': 'Бюджет',
  'news.News': 'Новости',
  'projects.Projects': 'Проекты',
  'projects.Add New': 'Создать',
  'projectView.project': 'Проект',
  'projectView.requisites': 'Реквизиты',
  'projectView.chronology': 'Хронология',
  'projectView.about': 'О проекте',
  'projectView.plan': 'План расходов',
  'projectView.results': 'Результаты',
  'projectView.news': 'Новости и публикации',
  'projectView.messages': 'Сообщения',
  'projectView.edit': 'Редактировать',

  'projectView.tags': 'Теги',
  'projectView.description': 'Описание',
  'projectView.dateStart': 'Дата Начала',
  'projectView.dateEnd': 'Дата Завершения',
  'projectView.dateSign': 'Дата Подписания',
  'projectView.donors': 'Доноры',
  'projectView.helpTypes': 'Типы Помощи',
  'projectView.payers': 'Плательщики',
  'projectView.executor': 'Исполнитель',
  'projectView.coordinator': 'Координатор',
  'projectView.coates': 'Населённые пункты',
  'projectView.sectors': 'Секторы',
  'projectView.country': 'Страна',
  'projectView.region': 'Район, область',
  'projectView.city': 'Город, село',
  'projectView.index': 'Индекс',
  'projectView.street': 'Улица',
  'projectView.homeNum': 'Дом №',
  'projectView.apartment': 'Кв.',
  'projectView.tel': 'Тел.',
  'projectView.email': 'Эл.почта',
  'projectView BankName': 'Наименование банка',
  'projectView BankData': 'Банковские данные',
  'projectView Address': 'Адресс',
  'projectView Bik': 'БИК',
  'projectView CheckingAccount': 'Расчетный счет',
  'projectView.activity': 'Деятельность',
  'projectView.goals': 'Цели и задачи',
  'projectView.sources': 'Источники финансирования',
  'projectView.reports': 'Отчёты проекта',
  'projectView.payouts': 'График выплат',
  'projectView.expectations': 'Ожидаемые результаты',
  'projectView.risks': 'Риски',
  'projectView.goals.dateFrom': 'С',
  'projectView.goals.dateTo': 'По',
};
