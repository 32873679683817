export default {
  'menu.home': 'Главная',
  'menu.Home': 'Главная',
  'menu.Dirs': 'Реестры',
  'menu.Projects': 'Проекты',
  'menu.Project': 'Проект',
  'menu.Organizations': 'Организации',
  'menu.News': 'Сообщения',
  'menu.News_public': 'Новости',
  'menu.Users': 'Пользователи',
  'menu.Tags': 'Теги',
  'menu.Login': 'Вход',
  'menu.Dir Entries': 'Записи справочника',
  'menu.View Project': 'Просмотр проекта',
  'menu.Edit/Create Project': 'Создать/Редактировать Проект',
  'menu.Edit Project': 'Редактировать Проект',
  'menu.Project Details': 'Детали Проекта',
  'menu.Create Project': 'Создать Проект',
  'menu.Coates': 'Населенные пункты',
  'menu.Okgz': 'ОКГЗ',
  'menu.About Page': 'О Платформе',
  'menu.Donors': 'Доноры',
  'menu.Edit/Create News': 'Создать/Редактировать Новость',
  'menu.View News': 'Просмотр Новости',
  'menu.Infographics': 'Инфографика',
};
