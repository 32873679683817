import {parse} from 'qs';
import {BASE_API_URL} from '../../config/contants';
import moment from 'moment';
import numeral from 'numeral';

/* eslint no-useless-escape:0 import/prefer-default-export:0 */

const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const isUrl = (path) => reg.test(path);

// export const isAntDesignPro = () => {
//   // if (ANT_DESIGN_PRO_ONLY_DO_NOT_USE_IN_YOUR_PRODUCTION === 'site') {
//   //   return true;
//   // }
//
//   return window.location.hostname === 'preview.pro.ant.design';
// }; // For the official demo site, it is used to turn off features that are not needed in the real development environment
//
// export const isAntDesignProOrDev = () => {
//   const { NODE_ENV } = process.env;
//
//   if (NODE_ENV === 'development') {
//     return true;
//   }
//
//   return isAntDesignPro();
// };

export const getPageQuery = () => parse(window.location.search);

export function updateTreeData(list, key, children) {
  return list.map((node) => {
    if (node.key === key) {
      return {...node, children};
    }

    if (node.children) {
      return {...node, children: updateTreeData(node.children, key, children)};
    }

    return node;
  });
}

export function getFileUrl(path) {
  return path ? `${BASE_API_URL}${path}` : void 0;
}

const NewsPlaceholder = 'https://www.pngrepo.com/png/204573/512/text-lines-newspaper.png';

export function getNewsAvatar(avatar) {
  return avatar ? getFileUrl(avatar.url) : NewsPlaceholder
}

export const arrayMove = (array, from, to) => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }

  return array;
};

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DB_DATE_FORMAT = 'YYYY-MM-DD';
export const DB_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'; //'YYYY-MM-DD HH:mm:ss';
export const FORMAT_DATE_TIME = 'D.MM.YYYY HH:mm';
export const FORMAT_MONEY = '0,0.00'; // 10 000,00

export const formatDateTime = (date, format = FORMAT_DATE_TIME) => {
  return moment(date).format(format);
};
export const formatDate = dt => {
  if (dt === undefined || dt === "" || dt === null) return "";
  return moment(dt).format(DATE_FORMAT);
};
export const formatDateDB = dt => {
  if (dt === undefined || dt === "" || dt === null) return "";
  return moment(dt).format(DB_DATE_FORMAT);
};
export const formatDateTimeDB = dt => {
  if (dt === undefined || dt === "" || dt === null) return "";
  return moment(dt).format(DB_DATETIME_FORMAT);
};

export const formatMoney = (amount, options = {}) => {
  // let format;

  if (amount === '' || amount === undefined || amount === null)
    return 'N/A';

  amount = parseFloat(amount);

  if (!isFinite(amount)) // NaN, Infinity, -Infinity
    return 'N/A';

  // if (options.format)
  //   format = options.format;
  // else if (options.currency || options.currency === undefined) // ON by default
  //   format = constants.FORMAT_MONEY_CURRENCY;
  // else
  //   format = constants.FORMAT_MONEY;

  return numeral(amount).format(FORMAT_MONEY, Math.round);
};

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

export const getAdminProps = ({user}) => ({
    isAdmin: (user.currentUser?.role.type === 'admin'),
});

export function getLocaleTitle(locale, item) {
  return getLocaleValue(locale, item, 'title')
}

export function getLocaleLabel(locale, item) {
  return getLocaleValue(locale, item, 'label')
}

export function getLocaleDescription(locale, item) {
  return getLocaleValue(locale, item, 'description')
}

export function getLocaleValue(locale, item, field) {
  if (!item)
    return null;
  let key = getLocaleFieldName(locale, field);
  return item[key] || item[field]
}

export function getLocaleFieldName(locale, field) {
  let key = (locale === 'en-US') ? `${field}_en` :
    (locale === 'ky-KG') ? `${field}_ky` : field;
  return key;
}
