import {
  gqlCreateEntry,
  gqlDeleteEntry,
  gqlMutate, gqlQuery,
  gqlQueryEntry,
  gqlQueryForSelect,
  gqlQueryTable,
  gqlUpdateEntry,
  saveEntriesGen,
  uploadFile,
} from '@/utils/graphql-client'
import gql from 'graphql-tag';
import moment from 'moment'
import {formatDateDB, formatDateTimeDB, getFileUrl} from '@/utils/utils'

/*
news
plans
coordinator
executor
donors
coates
sectors
payers
partner
tags
agreement
beneficiary
*/

export async function fetchProjects(tableQuery, addWhere, searchFields) {
  const query = gql`
    query($limit: Int, $start: Int, $sort: String, $where: JSON) {
      data: projects(limit: $limit, start: $start, sort: $sort, where: $where) {
        id, title, shortDescription, type, status {title}
        dateStart, dateEnd, dateSign, dateUpdated
      }
      total: projectsConnection(where: $where) {
        aggregate {count}
      }
    }
  `;
  return gqlQueryTable(query, tableQuery, addWhere, searchFields);
}

export async function fetchProject(id) {
  const query = gql`
    query($id: ID!) {
      entry: project(id: $id) {
        id, title, shortDescription, description, type,
        title_en, title_ky, shortDescription_en, shortDescription_ky, description_en, description_ky
        status {value: id, label: title, label_en: title_en, label_ky: title_ky}
        dateStart, dateEnd, dateSign
        # dateUpdated
        tags {value: id, label, label_en, label_ky}
        donors {value: id, label: title}
        payers {value: id, label: title}
        executor {value: id, label: title}
        coordinator {value: id, label: title}
        coates {value: id, label: title}
        sectors {value: id, label: title, label_en: title_en, label_ky: title_ky}
        helpTypes {value: id, label: title, label_en: title_en, lable_ky: title_ky}
      }
    }
  `;
  const item = await gqlQueryEntry(query, id);

  if (!item)
    return null;

  item.dateSign = item.dateSign ? moment(item.dateSign) : null;
  item.dateStart = item.dateStart ? moment(item.dateStart) : null;
  item.dateEnd = item.dateEnd ? moment(item.dateEnd) : null;

  return item;
}

export async function fetchProjectDetails(id) {
  const query = gql`
    query($id: ID!) {
      entry: project(id: $id) {
        id, title, shortDescription
        goals(sort: "order") {
          id, title, order, key: order, dateStart, dateEnd
          tasks(sort: "order") {
            id, title, order, key: order, dateStart, dateEnd
            actions(sort: "order") {
              id, title, order, key: order, dateStart, dateEnd
            }

          }
        }
        sources(sort: "order") {
          id, key: id, order, title, amount
          currency {value: id, label: title}
          bank {value: id, label: title}
        }
        reports(sort: "order") {
          id, key: id, order, title, deadline, note, type {value: id, label: title}
        }
        payments(sort: "order") {
          id, key: id, order, date, results, amount, currency {value: id, label: title}
        }
        risks(sort: "order") {
          id, key: id, title, signific, actions
        }
        expects(sort: "order") {
          key: id, id, product, indicator, result
          task {value: id, label: title}
        }

      }
    }
  `;

  let entry = await gqlQueryEntry(query, id);

  entry.goals.forEach(goal => {
    goal.dateStart = goal.dateStart ? moment(goal.dateStart) : null;
    goal.dateEnd = goal.dateEnd ? moment(goal.dateEnd) : null;
    goal.tasks.forEach(task => {
      task.dateStart = task.dateStart ? moment(task.dateStart) : null;
      task.dateEnd = task.dateEnd ? moment(task.dateEnd) : null;
      task.actions.forEach(action => {
        action.dateStart = action.dateStart ? moment(action.dateStart) : null;
        action.dateEnd = action.dateEnd ? moment(action.dateEnd) : null;
      })
    })
  });

  entry.payments.forEach(p => {
    p.date = p.date ? moment(p.date) : null;
  })

  entry.reports.forEach(p => {
    p.deadline = p.deadline ? moment(p.deadline) : null;
  })

  return entry;
}

export function fetchProjectRequisite(id) {
  const query = gql`
    query($id: ID!) {
      project(id: $id) {
        id, title
        requisite {
          id
          country region city index street house
          apt phone email bankCountry bank bik account
        }
      }
    }`;

  return gqlQuery(query, {id}, 'project')
}

export async function createProject(data) {
  prepareProjectData(data);

  const mutation = gql`
    mutation($input: createProjectInput) {
      res: createProject(input: $input) {
        entry: project {id}
      }
    }
  `;

  return gqlCreateEntry(mutation, data)
}

export async function updateProject(id, data) {
  prepareProjectData(data);

  const mutation = gql`
    mutation($input: updateProjectInput) {
      res: updateProject(input: $input) {
        entry: project {id}
      }
    }
  `;

  return gqlUpdateEntry(mutation, id, data);
}

export async function savePayers(payers) {
  if (!payers?.length)
    return;

  const queries = [];
  const types = [];
  const variables = {};

  payers.forEach(({id, organization: {value: orgId}, __typename, ...payer}, idx) => {
    const key = `i${idx}`;
    const rkey = `r${idx}`;

    if (id) {
      queries.push(`${rkey}: updatePayer(input: $${key}) {
        entry: payer {id}
      }`);
      types.push(`$${key}: updatePayerInput`);
      variables[key] = {
        data: {
          ...payer,
          organization: orgId,
        },
        where: {id},
      }

    } else {
      queries.push(`${rkey}: createPayer(input: $${key}) {
        entry: payer {id}
      }`);
      types.push(`$${key}: createPayerInput`);
      variables[key] = {
        data: {
          ...payer,
          organization: orgId,
        },
      }
    }
  });

  const query = `
    mutation(${types.join(', ')}) {
      ${queries.join('\n')}
    }
  `

  let res = await gqlMutate(gql(query), variables);

  if (res) {
    return payers.map((p, idx) => res[`r${idx}`]?.entry?.id)
  }
}

export async function saveProjectDetails(payload) {
  const {id, data} = payload;

  /// actions
  await saveEntriesGen(function*() {
    for (let goal of data.goals) {
      for (let task of goal.tasks) {
        for (let action of task.actions) {
          yield {
            item: action,
            entryName: 'action',
            d: {
              title: action.title,
              order: action.order,
              dateStart: action.dateStart ? moment(action.dateStart).format() : null,
              dateEnd: action.dateEnd ? moment(action.dateEnd).format() : null,
            }
          }
        }
      }
    }
  });

  /// tasks
  await saveEntriesGen(function*() {
    for (let goal of data.goals) {
      for (let task of goal.tasks) {
        yield {
          item: task,
          entryName: 'task',
          d: {
            title: task.title,
            order: task.order,
            dateStart: task.dateStart ? moment(task.dateStart).format() : null,
            dateEnd: task.dateEnd ? moment(task.dateEnd).format() : null,
            actions: task.actions.map(a => a.id).filter(Boolean),
          }
        }
      }
    }
  });

  /// goals
  await saveEntriesGen(function*() {
    for (let goal of data.goals) {
      yield {
        item: goal,
        entryName: 'goal',
        d: {
          project: id,
          title: goal.title,
          order: goal.order,
          dateStart: goal.dateStart ? moment(goal.dateStart).format() : null,
          dateEnd: goal.dateEnd ? moment(goal.dateEnd).format() : null,
          tasks: goal.tasks.map(t => t.id).filter(Boolean),
        }
      }
    }
  });

  /// sources
  await saveEntriesGen(function*() {
    for (let item of data.sources) {
      yield {
        item,
        entryName: 'projectSource',
        d: {
          project: id,
          title: item.title,
          order: item.order,
          bank: item.bank?.value,
          amount: isFinite(item.amount) ? parseFloat(item.amount) : null,
          currency: item.currency?.value
        }
      }
    }

    /// reports
    for (let item of data.reports) {
      yield {
        item,
        entryName: 'projectReport',
        d: {
          project: id,
          title: item.title,
          order: item.order,
          type: item.type?.value,
          deadline: item.deadline ? formatDateDB(item.deadline) : null,
          note: item.note
        }
      }
    }

    /// payments
    for (let item of data.payments) {
      yield {
        item,
        entryName: 'projectPayment',
        d: {
          project: id,
          date: item.date ? formatDateTimeDB(item.date) : null,
          results: item.results,
          order: item.order,
          amount: isFinite(item.amount) ? parseFloat(item.amount) : null,
          currency: item.currency?.value,
        }
      }
    }

    // expects
    for (let item of data.expects) {
      yield {
        item,
        entryName: 'projectExpect',
        d: {
          project: id,
          task: item.task ? item.task.value : null,
          product: item.product,
          indicator: item.indicator,
          result: item.result,
        }
      }
    }

    // risks
    for (let item of data.risks) {
      yield {
        item,
        entryName: 'projectRisk',
        d: {
          project: id,
          title: item.title,
          signific: item.signific,
          actions: item.actions,
        }
      }
    }
  });

  // await saveEntriesGen(function*() {
  //   yield {
  //     item: {id},
  //     entryName: 'project',
  //     d: {
  //       goals: data.goals.map(g => g.id).filter(Boolean),
  //       sources: data.sources.map(g => g.id).filter(Boolean),
  //       reports: data.reports.map(g => g.id).filter(Boolean),
  //       payments: data.payments.map(g => g.id).filter(Boolean),
  //     }
  //   }
  // })
}

export async function saveProjectRequisite(projectId, item) {
  return saveEntriesGen(function*() {
    const {id, ...data} = item;
    delete data.__typename;

    yield {
      entryName: 'projectRequisite',
      item,
      d: {
        project: projectId,
        ...data
      }
    }
  })
}

export async function deletePayers(ids) {
  const queries = [];

  if (!ids?.length)
    return;

  ids.map(id => {
    queries.push(`deletePayer(input: {where: {id: "${id}"}}) {
      payer {id}
    }`)
  });

  const query = `
    mutation {
      ${queries.join('\n')}
    }
  `;

  return gqlMutate(gql(query));
}

export async function deleteProject(id) {
  const mutation = gql`
    mutation($input: deleteProjectInput) {
      res: deleteProject(input: $input) {
        entry: project {id}
      }
    }
  `;

  return gqlDeleteEntry(mutation, id);
}

export async function fetchTagsForSelect(variables) {
  const query = gql`
    query($limit: Int, $start: Int, $sort: String, $where: JSON) {
      data: tags(limit: $limit, start: $start, sort: $sort, where: $where) {
        value: id, label, label_en, label_ky
      }
      total: tagsConnection(where: $where) {
        aggregate {count}
      }
    }
  `;

  return gqlQueryForSelect(query, variables);
}

export async function getTagsByLabel(tags) {
  let variables = {
    where: {
      label_in: tags
    }
  }

  const query = gql`
    query($where: JSON) {
      tags(limit: ${tags.length}, where: $where) {
        value: id, label
      }
    }
  `;

  return gqlQuery(query, variables, 'tags');
}

export async function fetchOrgsForSelect(variables) {
  const query = gql`
    query($limit: Int, $start: Int, $sort: String, $where: JSON) {
      data: organizations(limit: $limit, start: $start, sort: $sort, where: $where) {
        value: id, label: title
        logo {formats}
      }
      total: organizationsConnection(where: $where) {
        aggregate {count}
      }
    }
  `;

  return gqlQueryForSelect(query, variables);
}

export async function fetchProjectExpects(variables) {
  const query = gql`
    query($limit: Int, $start: Int, $sort: String, $where: JSON) {
      data: projectExpects(limit: $limit, start: $start, sort: $sort, where: $where) {
        value: id, label: indicator
        indicator, result
      }
      total: projectExpectsConnection(where: $where) {
        aggregate {count}
      }
    }
  `;

  return gqlQueryForSelect(query, variables);
}

export async function fetchDirEntriesForSelect(variables) {
  const query = gql`
    query($limit: Int, $start: Int, $sort: String, $where: JSON) {
      data: dirEntries(limit: $limit, start: $start, sort: $sort, where: $where) {
        value: id, label: title, label_en: title_en, label_ky: title_ky
      }
    }
  `;

  return gqlQueryForSelect(query, variables);
}

function selectToData(items) {
  return items?.map(i => i.value)
}

function prepareProjectData(data) {
  data.status = data.status?.value
  data.tags = selectToData(data.tags)
  data.donors = selectToData(data.donors)
  data.payers = selectToData(data.payers)
  // data.payers = selectToData(data.donors)
  data.executor = data.executor?.key
  data.coordinator = data.coordinator?.key
  data.coates = selectToData(data.coates);
  data.dateSign = data.dateSign?.format();
  data.dateStart = data.dateStart?.format();
  data.dateEnd = data.dateEnd?.format();
  data.sectors = selectToData(data.sectors);
  data.helpTypes = selectToData(data.helpTypes);
}

export function saveProjectPlans(id, items) {
  return saveEntriesGen(function *() {
    for (let item of items) {
      yield {
        item,
        entryName: 'projectPlanItem',
        d: {
          project: id,
          title: item.title,
          amountPlan: item.amountPlan ? parseFloat(item.amountPlan) : null,
          amountFact: item.amountFact ? parseFloat(item.amountFact) : null,
          amountRest: item.amountRest ? parseFloat(item.amountRest) : null,
        },
      }
    }
  })
}

export function saveProjectExpenses(id, items) {
  return saveEntriesGen(function *() {
    for (let item of items) {
      yield {
        item,
        entryName: 'projectExpense',
        d: {
          project: id,
          amount: item.amount ? parseFloat(item.amount) : null,
          description: item.description,
          planItem: item.planItem?.value,
          payer: item.payer?.value,
          payerAccount: item.payerAccount,
          payerBank: item.payerBank?.value,
          recipientName: item.recipientName,
          recipientAccount: item.recipientAccount,
          recipientBank: item.recipientBank?.value,
        },
      }
    }
  })
}

export async function fetchProjectGoalsAndTasks(variables) {
  const query = gql`
    query($limit: Int, $start: Int, $sort: String, $where: JSON, $whereTask: JSON) {
      data: goals(limit: $limit, start: $start, sort: $sort, where: $where) {
        label: title
        children: tasks(sort: "order", where: $whereTask) {
          value: id, label: title
        }
      }
      total: goalsConnection(where: $where) {
        aggregate {count}
      }
    }
  `;

  return gqlQueryForSelect(query, variables);
}

export function saveProjectIndicators(id, items) {
  return saveEntriesGen(function*() {
    for (let item of items) {
      yield {
        item,
        entryName: 'projectIndicator',
        d: {
          project: id,
          indicator: item.indicator?.value || null,
          result: item.result
        }
      }
    }
  });
}

export async function saveProjectResults(id, items) {
  let res = await saveEntriesGen(function*() {
    for (let item of items) {
      yield {
        item,
        entryName: 'projectResult',
        d: {
          project: id,
          task: item.task?.value || null,
          result: item.result,
          file: item.file ? undefined : null,
        }
      }
    }
  });

  items = items.filter(i => !i._deleted)

  await Promise.all(items.map(async r => {
    r.key = r.id;

    if (r.file?.originFileObj) {
      let res = await uploadFile(r.file.originFileObj, r.id, 'project-result', 'file');
      r.file = res;
    }
  }))

  return items;
}

export async function getProjectEditNewsItem(id) {
  const query = gql`
    query($id: ID!) {
      entry: projectNewsItem(id: $id) {
        id, title, description
        title_en, description_en
        title_ky, description_ky
        is_slider
        avatar {id name url mime formats}
        images {id name url mime formats}
        files {id name url mime formats}
      }
    }`

  /*
  upload:
    id
    name
    alternativeText
    caption
    width
    height
    formats
    hash
    ext
    mime
    size
    url
    previewUrl
  */

  let r = await gqlQueryEntry(query, id);

  if (r.avatar) {
    r.avatar = fileToForm(r.avatar)
  }

  if (r.images && r.images.length) {
    r.images = r.images.map(fileToForm)
  }

  if (r.files && r.files.length) {
    r.files = r.files.map(fileToForm)
  }

  r.published_at = r.published_at ? moment(r.published_at): null;
  r.updated_at = r.published_at ? moment(r.updated_at): null;

  return r;
}

function fileToForm(img) {
  return {
    status: 'done',
    uid: img.id,
    name: img.name,
    url: getFileUrl(img.url),
    previewImage: getFileUrl(img.url),
    thumbUrl: img.formats ? getFileUrl(img.formats.thumbnail?.url) : null,
    type: img.mime,
  }
}

export async function saveProjectNewsItem(id, item, data) {

  await saveEntriesGen(function*() {
    delete data.__typename
    yield {
      item,
      entryName: 'projectNewsItem',
      d: {
        project: id,
        title: data.title,
        title_en: data.title_en,
        title_ky: data.title_ky,
        description: data.description,
        description_en: data.description_en,
        description_ky: data.description_ky,
        is_slider: !!data.is_slider
      }
    }
  });

  if (item.id) {
    let {id} = item;

    debugger
    if (data.avatar) {
      let file = data.avatar;
      if (file.originFileObj) {
        debugger
        await uploadFile(file.originFileObj, id, 'project-news-item', 'avatar')
      }
    }

    if (data.files && data.files.length) {
      await Promise.all(data.files.map(async file => {
        if (file.originFileObj) {
          debugger
          await uploadFile(file.originFileObj, id, 'project-news-item', 'files')
        }
      }))
    }

    if (data.images && data.images.length) {
      await Promise.all(data.images.map(async file => {
        if (file.originFileObj) {
          debugger
          await uploadFile(file.originFileObj, id, 'project-news-item', 'images')
        }
      }))
    }
  }

  return item;
}

export function deleteProjectNewsItem(id) {
  const mutation = gql`
    mutation($input: deleteProjectNewsItemInput) {
      res: deleteProjectNewsItem(input: $input) {
        entry: projectNewsItem {id}
      }
    }
  `;

  return gqlDeleteEntry(mutation, id);
}

