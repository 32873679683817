import {notification} from 'antd';
import {
  createTag,
  deleteTag,
  fetchTag,
  fetchTags,
  updateTag,
} from './service';

const MODEL_SCOPE = 'adminTags';

const Model = {
  namespace: MODEL_SCOPE,
  state: {
    editItem: null
  },
  effects: {
    *fetchItems({payload}, {call}) {
      const res = yield call(fetchTags, payload);

      if (res) {
        const {data, total} = res;
        return {
          data,
          total,
          success: true
        }
      }

      return null;
    },
    *setEditItem({payload: id}, {call, put}) {
      if (id === null) {
        yield put({type: 'setStateEditItem', payload: null});

      } else if (id === 'new') {
        yield put({type: 'setStateEditItem', payload: {}});

      } else {
        const item = yield call(fetchTag, id);
        if (item) {
          yield put({type: 'setStateEditItem', payload: item});
        }
      }
    },
    *saveItem({payload}, {call, select}) {
      const {id, ...data} = payload;

      if (id) {
        const res = yield call(updateTag, id, data);
        if (res) {
          notification.success({message: 'Updated successfully'});
          return true;
        }
      } else {
        const dirId = yield select(state => state[MODEL_SCOPE].dirId);
        const res = yield call(createTag, {...data, dir: dirId});
        if (res) {
          notification.success({message: 'Created successfully'});
          return true;
        }
      }

      return false;
    },
    *deleteItem({payload: id}, {call}) {
      const res = yield call(deleteTag, id);
      if (res) {
        notification.success({message: 'Deleted successfully'});
        return true;
      }
      return false;
    },
  },
  reducers: {
    setStateEditItem(state, {payload: editItem}) {
      return {...state, editItem};
    },
  }
};
export default Model;
