import {notification} from 'antd';
import {createDirEntry, deleteDirEntry, fetchDir, fetchDirEntries, fetchDirEntry, updateDirEntry} from './service';

const MODEL_SCOPE = 'adminDirsEntries';

const Model = {
  namespace: MODEL_SCOPE,
  state: {
    dirId: null,
    dir: null,
    editItem: null
  },
  effects: {
    *fetchDir({payload: dirName}, {call, put}) {
      const dir = yield call(fetchDir, dirName);
      if (dir) {
        yield put({type: 'setDir', payload: dir})
      }
    },
    *fetchItems({payload}, {call, select}) {
      const dirId = yield select(state => state[MODEL_SCOPE].dir.id);
      const res = yield call(fetchDirEntries, payload, dirId);

      if (res) {
        return {
          data: res.data,
          total: res.total,
          success: true
        }
      }

      return null;
    },
    *setEditItem({payload: id}, {call, put}) {
      if (id === null) {
        yield put({type: 'setStateEditItem', payload: null});

      } else if (id === 'new') {
        const item = {
          id: null, title: ''
        }
        yield put({type: 'setStateEditItem', payload: item});

      } else {
        const item = yield call(fetchDirEntry, id);
        if (item) {
          yield put({type: 'setStateEditItem', payload: item});
        }
      }
    },
    *saveItem({payload}, {call, select}) {
      const {id, ...data} = payload;

      if (id) {
        const res = yield call(updateDirEntry, id, data);
        if (res) {
          notification.success({message: 'Updated successfully'});
          return true;
        }
      } else {
        const dirId = yield select(state => state[MODEL_SCOPE].dir.id);
        const res = yield call(createDirEntry, {...data, dir: dirId});
        if (res) {
          notification.success({message: 'Created successfully'});
          return true;
        }
      }

      return false;
    },
    *deleteItem({payload: id}, {call}) {
      const res = yield call(deleteDirEntry, id);
      if (res) {
        notification.success({message: 'Deleted successfully'});
        return true;
      }
      return false;
    },
  },
  reducers: {
    setDir(state, {payload: dir}) {
      return {...state, dir}
    },
    setStateEditItem(state, {payload: editItem}) {
      return {...state, editItem};
    },
  }
};
export default Model;
