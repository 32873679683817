import {message} from 'antd';
import {createDir, deleteDir, fetchDir, fetchDirs, updateDir} from './service';

const MODEL_SCOPE = 'adminDirs';

const Model = {
  namespace: MODEL_SCOPE,
  state: {
    editItem: null
  },
  effects: {
    *fetchDirs({payload}, {call}) {
      // TODO: use filter
      // eslint-disable-next-line no-unused-vars
      const {params, sorter, filter} = payload;

      let key, sort;
      if (sorter) {
        [key] = Object.keys(sorter);
      }
      if (key) {
        sort = `${key}:${sorter[key] === 'ascend' ? 'asc' : 'desc'}`;
      } else {
        sort = 'id';
      }

      const start = (params.current - 1) * params.pageSize;

      try {
        const response = yield call(fetchDirs, {
          limit: params.pageSize,
          start,
          sort,
        });

        return {
          data: response?.data?.dirs,
          success: true,
          total: response?.data?.dirsConnection?.aggregate?.count
        };

      } catch (e) {
        message.error(e.message || 'Error');
        return null;
      }
    },
    *deleteItem({payload: id}, {call}) {
      try {
        yield call(deleteDir, id);
        message.success('Deleted successfully');
        return true;
      } catch (e) {
        message.error(e.message || 'Error');
        return false;
      }
    },
    *setEditItem({payload: id}, {call, put}) {
      if (id === null) {
        yield put({type: 'setStateEditItem', payload: null});

      } else if (id === 'new') {
        const item = {
          id: null, title: '', description: '', name: '', external: false
        }
        yield put({type: 'setStateEditItem', payload: item});

      } else {
        try {
          const res = yield call(fetchDir, id);
          const item = res.data.dir;
          yield put({type: 'setStateEditItem', payload: item});

        } catch (e) {
          message.error(e.message || 'Error');
        }
      }
    },
    *saveItem({payload}, {call}) {
      const {id, ...data} = payload;

      try {
        if (id) {
          // eslint-disable-next-line no-underscore-dangle
          delete data.__typename;
          yield call(updateDir, {id, data});
        } else {
          yield call(createDir, data);
        }
        message.success('Submitted successfully');
        return true;

      } catch (e) {
        message.error(e.message || 'Error');
        return false;
      }
    },
  },
  reducers: {
    setStateEditItem(state, {payload}) {
      return {...state, editItem: payload};
    }
  }
};
export default Model;
