export default {
  'menu.home': 'Home',
  'menu.Home': 'Home',
  'menu.Dirs': 'Registries',
  'menu.Projects': 'Projects',
  'menu.Project': 'Project',
  'menu.Organizations': 'Organizations',
  'menu.News': 'Messages',
  'menu.News_public': 'News',
  'menu.Users': 'Users',
  'menu.Tags': 'Tags',
  'menu.Login': 'Login',
  'menu.Dir Entries': 'Dir Entries',
  'menu.View Project': 'View Project',
  'menu.Edit/Create Project': 'Edit/Create Project',
  'menu.Edit Project': 'Edit Project',
  'menu.Create Project': 'Create Project',
  'menu.Project Details': 'Project Details',
  'menu.Coates': 'Coates',
  'menu.Okgz': 'OKGZ',
  'menu.About Page': 'About Platform',
  'menu.Donors': 'Donors',
  'menu.Edit/Create News': 'Edit/Create News',
  'menu.View News': 'View News',
  'menu.Infographics': 'Infographics',
};
