import {queryCurrent} from '@/models/services/user';

const UserModel = {
  namespace: 'user',
  state: {
    currentUser: null,
  },
  effects: {
    // *fetch(_, { call, put }) {
    //   const response = yield call(queryUsers);
    //   yield put({
    //     type: 'save',
    //     payload: response,
    //   });
    // },

    *fetchCurrent(_, {call, put}) {
      const token = localStorage.getItem('token');
      if (!token)
        return null;

      const user = yield call(queryCurrent);
      if (user) {
        yield put({type: 'setUser', payload: user});
      }
    },
  },
  reducers: {
    setUser(state, {payload}) {
      return {...state, currentUser: payload};
    },

    changeNotifyCount(
      state = {
        currentUser: {},
      },
      action,
    ) {

      debugger

      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
  },
};
export default UserModel;
