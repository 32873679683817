export default {
  'menu.home': 'Башкы бет',
  'menu.Home': 'Башкы бет',
  'menu.Dirs': 'Реестрлер',
  'menu.Projects': 'Долбоорлор',
  'menu.Project': 'Долбоор',
  'menu.Organizations': 'Уюмдар',//Организации
  'menu.News': 'Билдирүүлөр',
  'menu.News_public': 'Жанылыктар',
  'menu.Users': 'Колдонуучулар',
  'menu.Tags': 'Тегдер',
  'menu.Login': 'Чыгуу',
  'menu.Dir Entries': 'Записи справочника',
  // 'menu.Dir Entries': 'Записи справочника',
  'menu.View Project': 'Долбоорду көрүү',
  'menu.Edit/Create Project': 'Создать/Редактировать Проект',
  'menu.Edit Project': 'Долбоорду түзөтүү',
  'menu.Create Project': 'Долбоор түзүү',
  'menu.Project Details': 'Проектин түзүмү',
  'menu.Coates': 'Населенные пункты',
  'menu.Okgz': 'ОКГЗ',
  'menu.About Page': 'О Платформе',
  'menu.Donors': 'Доноры',
  'menu.Edit/Create News': 'Создать/Редактировать Новость',
  'menu.View News': 'Просмотр Новости',
  'menu.Infographics': 'Инфографика',
};
