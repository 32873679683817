import request from '@/utils/request';
import {gql} from "@apollo/client";
import {gqlQuery} from '@/utils/graphql-client'
// import {gql} from '@apollo/client';

// const EXCHANGE_RATES = gql`
//   query GetExchangeRates {
//     rates(currency: "USD") {
//       currency
//       rate
//     }
//   }
// `;

export async function query() {
  debugger
  return request('/api/users');
}

export async function queryCurrent() {
  const query = gql`
    query {
      me {
        id
        username
        email
        confirmed
        blocked
        role {
          id
          name
          description
          type
        }
        avatar {url, formats}
      }
    }
  `;

  return gqlQuery(query, null, 'me', true)
}
export async function queryNotices() {
  debugger
  return request('/api/notices');
}

export const getUsers = gql`
      query($limit: Int, $start: Int, $sort: String){
        users(limit: $limit, start: $start, sort: $sort){
          id, created_at, updated_at, username, email, provider, confirmed, blocked, fullname, phone
        }
      }
  `;



// export async function login(username, password) {
//   return client.query(gql`
//     mutation {
//       login(input: { identifier: "email", password: "password" }) {
//         jwt
//       }
//     }`
//   )
// }
