export default {
  'formDir.name.label': 'Имя',
  'formDir.name.required': 'Имя обязательно',
  'formDir.title.label': 'Название',
  'formDir.title.required': 'Название обязательно',
  'formDir.description.label': 'Описание',
  'formDir.description.required': 'Описание обязательно',
  'formDir.external.label': 'Внешний',
  'formDir.submit': 'Отправить',
  'formDir.cancel': 'Отмена',
};
