// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/var/www/platform1-client-pro/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BaseLayout' */'/var/www/platform1-client-pro/src/layouts/BaseLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__MainLayout' */'/var/www/platform1-client-pro/src/layouts/MainLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "redirect": "/home",
            "exact": true
          },
          {
            "name": "Home",
            "path": "/home",
            "icon": "home",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Home' */'/var/www/platform1-client-pro/src/pages/Home'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Login",
            "path": "/user/login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__login' */'/var/www/platform1-client-pro/src/pages/user/login'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "About Page",
            "path": "/admin/about",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__about' */'/var/www/platform1-client-pro/src/pages/admin/about'), loading: LoadingComponent}),
            "hideInMenu": true,
            "authority": [
              "admin"
            ],
            "exact": true
          },
          {
            "name": "Dirs",
            "path": "/admin/dirs",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__dirs' */'/var/www/platform1-client-pro/src/pages/admin/dirs'), loading: LoadingComponent}),
            "authority": [
              "admin"
            ],
            "exact": true
          },
          {
            "name": "Dir Entries",
            "path": "/admin/dirs/:dirName/entries",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__dirs__entries' */'/var/www/platform1-client-pro/src/pages/admin/dirs/entries'), loading: LoadingComponent}),
            "hideInMenu": true,
            "authority": [
              "admin"
            ],
            "exact": true
          },
          {
            "name": "Organizations",
            "path": "/admin/organizations",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__organizations' */'/var/www/platform1-client-pro/src/pages/admin/organizations'), loading: LoadingComponent}),
            "hideInMenu": true,
            "authority": [
              "admin"
            ],
            "exact": true
          },
          {
            "name": "Projects",
            "path": "/admin/projects",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__projects' */'/var/www/platform1-client-pro/src/pages/admin/projects'), loading: LoadingComponent}),
            "authority": [
              "admin"
            ],
            "exact": true
          },
          {
            "name": "Projects",
            "path": "/projects",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__projects' */'/var/www/platform1-client-pro/src/pages/projects'), loading: LoadingComponent}),
            "authority": [
              "public",
              "authenticated"
            ],
            "exact": true
          },
          {
            "name": "News_public",
            "path": "/news",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__news__index' */'/var/www/platform1-client-pro/src/pages/news/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Donors",
            "path": "/donors",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__donors' */'/var/www/platform1-client-pro/src/pages/donors'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Infographics",
            "path": "/infogr",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__infogr' */'/var/www/platform1-client-pro/src/pages/infogr'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Projects",
            "path": "/projects",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__projects' */'/var/www/platform1-client-pro/src/pages/projects'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "View Project",
            "path": "/projects/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__projects__view' */'/var/www/platform1-client-pro/src/pages/projects/view'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "Edit/Create Project",
            "path": "/admin/projects/edit/:id?",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__projects__edit' */'/var/www/platform1-client-pro/src/pages/admin/projects/edit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "authority": [
              "admin"
            ],
            "exact": true
          },
          {
            "name": "Edit/Create Project",
            "path": "/admin/projects/editreq/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__projects__editreq' */'/var/www/platform1-client-pro/src/pages/admin/projects/editreq'), loading: LoadingComponent}),
            "hideInMenu": true,
            "authority": [
              "admin"
            ],
            "exact": true
          },
          {
            "name": "Project Details",
            "path": "/admin/projects/details/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__projects__details' */'/var/www/platform1-client-pro/src/pages/admin/projects/details'), loading: LoadingComponent}),
            "hideInMenu": true,
            "authority": [
              "admin"
            ],
            "exact": true
          },
          {
            "name": "Coates",
            "path": "/admin/coate",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__coate' */'/var/www/platform1-client-pro/src/pages/admin/coate'), loading: LoadingComponent}),
            "authority": [
              "admin"
            ],
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "Okgz",
            "path": "/admin/okgz",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__okgz' */'/var/www/platform1-client-pro/src/pages/admin/okgz'), loading: LoadingComponent}),
            "authority": [
              "admin"
            ],
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "News",
            "path": "/admin/news",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__news' */'/var/www/platform1-client-pro/src/pages/admin/news'), loading: LoadingComponent}),
            "authority": [
              "admin"
            ],
            "exact": true
          },
          {
            "name": "Edit/Create News",
            "path": "/admin/news/edit/:id?",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__news__edit' */'/var/www/platform1-client-pro/src/pages/admin/news/edit'), loading: LoadingComponent}),
            "authority": [
              "admin"
            ],
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "View News",
            "path": "/news/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__news__view' */'/var/www/platform1-client-pro/src/pages/news/view'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "Tags",
            "path": "/admin/tags",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__tags' */'/var/www/platform1-client-pro/src/pages/admin/tags'), loading: LoadingComponent}),
            "authority": [
              "admin"
            ],
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "Users",
            "path": "/admin/users",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__users' */'/var/www/platform1-client-pro/src/pages/admin/users'), loading: LoadingComponent}),
            "authority": [
              "admin"
            ],
            "exact": true
          }
        ]
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/www/platform1-client-pro/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
