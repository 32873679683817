import client from '@/utils/graphql-client'
import gql from 'graphql-tag';

export async function fetchDirs(variables) {
  const fetchDirsQuery = gql`
    query getDirs($limit: Int, $start: Int, $sort: String){
      dirs(limit: $limit, start: $start, sort: $sort){
        id, title, description, name, external
        title_en, title_ky, description_en, description_ky
      }
      dirsConnection {
        aggregate {
          count
        }
      }
    }
  `;
  return client.query({query: fetchDirsQuery, variables});
}

export async function fetchDir(id) {
  const query = gql`
    query getDir($id: ID!){
      dir(id: $id){
        id, title, description, name, external
        title_en, title_ky, description_en, description_ky
      }
    }
  `;
  return client.query({query, variables: {id}});
}

export async function createDir(data) {
  const mutation = gql`
    mutation createDir($input: createDirInput) {
      createDir(input: $input) {
        dir {
          id
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: {
      input: {data}
    }
  });
}

export async function updateDir({id, data}) {
  const mutation = gql`
    mutation updateDir($input: updateDirInput) {
      updateDir(input: $input) {
        dir {
          id
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: {
      input: {
        where: {id},
        data
      }
    }
  });
}

export async function deleteDir(id) {
  const mutation = gql`
    mutation deleteDir($input: deleteDirInput) {
      deleteDir(input: $input) {
        dir {
          id
        }
      }
    }
  `;

  return client.mutate({
    mutation,
    variables: {
      input: {
        where: {id}
      }
    }
  });
}
